import React from 'react'
import FormComp from './FormComp'

import { Container } from 'react-bootstrap'

const AddItems = () => {
    return (
        <>
            <br /><br /><br />
            <Container>
                <FormComp />
                <br /><br />
            </Container>
            <br />
        </>
    )
}

export default AddItems